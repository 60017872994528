
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `将其添加到主屏幕，以享受更丰富的体验并便于访问。`,
's1b9047d53d9f9d22': `2) 点击导航栏中的“分享”按钮`,
's37a9e8aec5713460': `更多`,
's5c6aad7a7e4a1437': `3) 点击“添加到主屏幕”`,
's6196153c4b0c1ea0': `安装`,
's922329d6f6213590': `添加到程序坞`,
'sa5ef80b4bb9b39f8': `收起`,
'sa7551383d1897fcc': `2) 点击“添加到程序坞”`,
'sba52286c21552a4e': `在您的设备上安装，以享受更丰富的体验并便于访问。`,
'sc79fd7641eb9a975': `隐藏安装步骤`,
'scdaf4bbff76674c8': `添加到主屏幕`,
'sdfe23506a3b0cdc8': `1) 在 Safari 浏览器中打开此页面`,
'se740f75b95a51807': `将其添加到您的程序坞，以享受更全面的体验并便于访问。`,
'sfea652f6580ff086': `此网站具备 App 功能特性。`,
    };
  